import { createContext } from 'react'
import { currentEnv, appURL, apiURL, embedAppURL } from '../../services/env'
import Environment from '../../constants/env'

const EnvironmentProvider = createContext({
    name: currentEnv,
    appURL,
    apiURL,
    embedAppURL,
    isLocal: currentEnv === Environment.LOCAL,
    isProd: currentEnv === Environment.PROD
})

export default EnvironmentProvider
