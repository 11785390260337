import { useIntl } from 'react-intl'
import defaultMessages from '../../locales/en'

interface Values {
    [key: string] :string
}

/**
 * Generate a format message intl decryptor.
 * @return {Function} A format message decryptor.
 */
const useFormatMessage = () => {
    const intl = useIntl()
    return (id: string, values?: Values) => (
        defaultMessages[id] ? (
            intl.formatMessage(
                { id, defaultMessage: defaultMessages[id] },
                values as Values
            )
        ) : (
            id
        )
    )
}

export default useFormatMessage
