export const Environment = {
    PROD: 'PROD',
    STAGING: 'STAGING',
    BETA: 'BETA',
    ALPHA: 'ALPHA',
    DEV: 'DEV',
    LOCAL: 'LOCAL'
}

export const ResourceTarget = {
    FORM: 'form',
    DASHBOARD: 'dashboard'
}

/**
 * Retrieves from environment the target application resource.
 *
 * @throws In case REACT_APP_RESOURCE_TARGET or REACT_APP_COMMERCIAL_NAME don't have
 * been set for the project or have an invalid value.
 * @return {string} The application resource type.
 */
const getAppResourceTarget = () => {
    const appCommercialName = process.env.REACT_APP_COMMERCIAL_NAME
    if (!appCommercialName) {
        throw new Error('The environment variable REACT_APP_COMMERCIAL_NAME needs to be set for this project')
    }

    const appResourceTarget = process.env.REACT_APP_RESOURCE_TARGET
    if (appResourceTarget && typeof appResourceTarget === 'string') {
        const value = appResourceTarget.toLowerCase()
        if (Object.values(ResourceTarget).includes(value)) return value
    }
    throw new Error('The environment variable REACT_APP_RESOURCE_TARGET needs to be set for this project')
}

export const RESOURCE_TARGET = getAppResourceTarget()

export default Environment
