import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: object) => ({
    root: {
        height: '100vh',
        overflow: 'hidden'
    },
    container: {
        height: '100%',
        flex: 1
    }
}))

export default useStyles as Function
