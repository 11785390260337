import { RESOURCE_TARGET, Environment } from '../../constants/env'
import EnvironmentInterface from '../../interfaces/Environment'

const baseAppURL = `${RESOURCE_TARGET}s.sheetgo.com`
const baseApiURL = `${RESOURCE_TARGET}s-api.sheetgo.com`

const envs = {
    [Environment.PROD]: {
        name: Environment.PROD,
        appURL: `https://${baseAppURL}`,
        apiURL: `https://${baseApiURL}`,
        embedAppURL: 'https://app.sheetgo.com'
    },
    [Environment.STAGING]: {
        name: Environment.STAGING,
        appURL: `https://staging-${baseAppURL}`,
        apiURL: `https://staging-${baseApiURL}`,
        embedAppURL: 'https://staging.sheetgo.com'
    },
    [Environment.DEV]: {
        name: Environment.DEV,
        appURL: `https://dev-${baseAppURL}`,
        apiURL: `https://dev-${baseApiURL}`,
        embedAppURL: 'https://dev.sheetgo.com'
    },
    [Environment.BETA]: {
        name: Environment.BETA,
        appURL: `https://beta-${baseAppURL}`,
        apiURL: `https://beta-${baseApiURL}`,
        embedAppURL: 'https://beta.sheetgo.com'
    },
    [Environment.ALPHA]: {
        name: Environment.ALPHA,
        appURL: `https://alpha-${baseAppURL}`,
        apiURL: `https://alpha-${baseApiURL}`,
        embedAppURL: 'https://alpha.sheetgo.com'
    },
    [Environment.LOCAL]: {
        name: Environment.LOCAL,
        appURL: `https://local-${baseAppURL}`,
        /** Default LOCAL backend config */
        apiURL: `https://local-${baseApiURL}`,
        embedAppURL: 'https://local.sheetgo.com'
        /** Alternative PROD backend for development */
        // apiURL: `https://${baseApiURL}`,
        // embedAppURL: 'https://app.sheetgo.com'
    }
}

if (window.location.hostname === 'localhost') {
    window.location.replace(envs[Environment.LOCAL].appURL)
}

const settings = Object.values(envs)
    .find((env) => env.appURL.includes(window.location.hostname)) as EnvironmentInterface

const { name: currentEnv } = settings

const isLocalEnv = currentEnv === envs[Environment.LOCAL].name
const prodEnvSettings = envs[Environment.PROD]
const isProdEnv = [Environment.PROD].includes(currentEnv)

const {
    appURL,
    apiURL,
    embedAppURL
} = settings

export {
    currentEnv, appURL, apiURL, embedAppURL,
    isLocalEnv, isProdEnv, prodEnvSettings
}
