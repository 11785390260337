import { RESOURCE_TARGET, ResourceTarget } from '../../../constants/env'

interface Locale {
    [key: string]: string
}

const resourceName = RESOURCE_TARGET === ResourceTarget.DASHBOARD
    ? 'dashboard'
    : 'form'

export default {
    'GENERAL.TRY_AGAIN': 'Try again',
    'DOCUMENT_TITLE.DEFAULT': `Sheetgo ${resourceName.charAt(0).toUpperCase() + resourceName.slice(1)}s`,
    'EMBEDDED_LOADER.ERROR.NOT_FOUND.TITLE': `Sorry, this ${resourceName} was not found`,
    'EMBEDDED_LOADER.ERROR.NOT_FOUND.DESCRIPTION':
        `The ${resourceName} you are looking for does not exist or it has been permanently deleted.`,
    'EMBEDDED_LOADER.ERROR.NOT_FOUND.PRIMARY_ACTION': 'Go to Sheetgo home page',
    'EMBEDDED_LOADER.ERROR.SESSION_EXPIRED.TITLE': 'Your session has expired',
    'EMBEDDED_LOADER.ERROR.SESSION_EXPIRED.DESCRIPTION':
        'Due to inactivity or security reasons, we have ended your session.',
    'EMBEDDED_LOADER.ERROR.SESSION_EXPIRED.DESCRIPTION.HINT': 'Please try reloading the page to continue.',
    'EMBEDDED_LOADER.ERROR.SESSION_EXPIRED.DESCRIPTION.SUPPORT': 'If the problem persists, {action}.',
    'EMBEDDED_LOADER.ERROR.SESSION_EXPIRED.DESCRIPTION.SUPPORT.ACTION': 'contact our support team',
    'EMBEDDED_LOADER.ERROR.SESSION_EXPIRED.RELOAD_PAGE': 'Reload page',
    'EMBEDDED_LOADER.ERROR.UNKNOWN.TITLE': `There was an error loading the requested ${resourceName}.`,
    'EMBEDDED_LOADER.ERROR.UNKNOWN.PRIMARY_ACTION': 'Try again'
} as Locale
