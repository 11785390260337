import React, { Suspense, lazy, useEffect } from 'react'
import { Routes, Route } from 'react-router-dom'

import { Grid } from '@mui/material'
import useStyles from './styles'

import * as ROUTES from '../../constants/routes'
import useFormatMessage from '../../i18n/hooks/useFormatMessage'

const Root = lazy(() => import('../Root' /* webpackChunkName: "Root" */))
const EmbeddedLoader = lazy(() => import('../EmbeddedLoader' /* webpackChunkName: "EmbeddedLoader" */))

export default function Container() {
    const classes = useStyles()
    const formatMessage = useFormatMessage()

    useEffect(() => {
        document.title = formatMessage('DOCUMENT_TITLE.DEFAULT')
    }, [])

    return (
        <Grid
            className={classes.root}
            justifyContent="center"
            alignItems="flex-start"
            container
            item
        >
            <Grid className={classes.container} container item>
                <Routes>
                    <Route
                        path={ROUTES.PUBLIC_RESOURCE}
                        element={(
                            <Suspense fallback={<></>}>
                                <EmbeddedLoader />
                            </Suspense>
                        )}
                    />
                    <Route
                        path="*"
                        element={(
                            <Suspense fallback={<></>}>
                                <Root />
                            </Suspense>
                        )}
                    />
                </Routes>
            </Grid>
        </Grid>
    )
}
