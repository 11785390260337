import React, { useMemo } from 'react'

import { ThemeProvider } from '@mui/material'
import theme from './styles/MuiTheme'

import Environment from './constants/env'
import { currentEnv, appURL, apiURL, embedAppURL } from './services/env'

import Container from './components/Container'
import IntlProvider from './i18n/IntlProvider'
import EnvironmentProvider from './components/EnvironmentProvider'
import GlobalCssPriority from './components/GlobalCssPriority'

function App() {
    const env = useMemo(() => ({
        name: currentEnv,
        appURL,
        apiURL,
        embedAppURL,
        isLocal: currentEnv === Environment.LOCAL,
        isProd: currentEnv === Environment.PROD
    }), [currentEnv, appURL, apiURL, embedAppURL])

    return (
        <ThemeProvider theme={theme}>
            <GlobalCssPriority>
                <IntlProvider>
                    <EnvironmentProvider.Provider value={env}>
                        <Container/>
                    </EnvironmentProvider.Provider>
                </IntlProvider>
            </GlobalCssPriority>
        </ThemeProvider>
    )
}

export default App
