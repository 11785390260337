import en from './en'

interface Locale {
    [key: string]: string
}

interface LocaleGroup {
    [key: string]: Locale
}

export default { en } as LocaleGroup
