import React, { ReactNode, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { IntlProvider as Provider } from 'react-intl'

import locales from '../locales'

const defaultLocale = 'en'

interface IntlProviderProps {
    preferredLocale: string,
    children: ReactNode
}

/**
 * Intl provider wrapper.
 *
 * @param {IntlProviderProps} props - Props to the component.
 * @return {Provider} The intl provider.
 */
export default function IntlProvider({ preferredLocale, children }: IntlProviderProps) {
    const [i18nMessages, setI18nMessages] = useState(locales[defaultLocale])
    const [i18nLocale, setI18nLocale] = useState(defaultLocale)

    /**
     * Handle generating intl for the given preferred locale.
     */
    const handleLocale = () => {
        // Define user's browser language. Different browsers have the user locale defined on different fields
        // on the 'navigator' object, so we make sure to account for these different by checking all of them
        const browserLanguage = (
            navigator.languages && navigator.languages[0]
        ) || (
            // @ts-ignore: Deprecated userLanguage in navigator
            navigator.language || navigator.userLanguage
        )

        // Try preferred locale
        let messages = locales[preferredLocale]
        let locale = preferredLocale

        if (messages === undefined) {
            // Try full locale
            messages = locales[browserLanguage]
            locale = browserLanguage
        }

        if (messages === undefined) {
            // Try locale without region code
            const languageWithoutRegionCode = browserLanguage.toLowerCase().split(/[_-]+/)[0]
            messages = locales[languageWithoutRegionCode]
            locale = languageWithoutRegionCode
        }

        if (messages === undefined) {
            // Fallback to default locale
            messages = locales[defaultLocale]
            locale = defaultLocale
        }
        setI18nMessages(messages)
        setI18nLocale(locale)
    }

    useEffect(() => {
        handleLocale()
    }, [preferredLocale])

    return (
        <Provider
            locale={i18nLocale}
            defaultLocale={defaultLocale}
            messages={i18nMessages}
            onError={() => {}}
            wrapRichTextChunksInFragment
        >
            {children}
        </Provider>
    )
}

IntlProvider.propTypes = {
    children: PropTypes.node.isRequired,
    preferredLocale: PropTypes.string
}

IntlProvider.defaultProps = {
    preferredLocale: defaultLocale
}
