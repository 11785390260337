import React, { ReactNode } from 'react'
import { StyledEngineProvider } from '@mui/material/styles'

interface GlobalCssPriorityProps {
    children: ReactNode
}

export default function GlobalCssPriority({ children }: GlobalCssPriorityProps) {
    return (
        <StyledEngineProvider injectFirst>
            {children}
        </StyledEngineProvider>
    )
}
